import {Injectable} from '@angular/core';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  startAfter,
  where,
  writeBatch
} from '@angular/fire/firestore';
import {BehaviorSubject, first, lastValueFrom} from 'rxjs';
import {TenantService} from '../../../services/tenant.service';
import {MessagesService} from '../../../shared/services/messages.service';
import {CustomPage} from '../../../models/page-builder/custom-page.model';
import {Timestamp} from '@firebase/firestore';
import {createFirestoreId} from '../../../common/firestore-utils';
import {LoadingService} from '../../../shared/services/loading.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {EditSectionService} from '../../page-editor/services/edit-section.service';
import {map} from 'rxjs/operators';
import {instructorPlans} from '../../../admin/school-settings/instructor-plans';

@Injectable({
  providedIn: 'root'
})
export class SitePagesService {

  sitePages$ = new BehaviorSubject<CustomPage[]>([])
  homePage$ = new BehaviorSubject<CustomPage>(null)

  constructor(private tenant: TenantService,
              private messagesService: MessagesService,
              private firestore: Firestore,
              private loadingService: LoadingService,
              private snackBar: MatSnackBar,
              private editService: EditSectionService
  ) { }

  async loadSitePages(pageNumber: number,  lastPostDoc?: Timestamp) {
    // load site pages from schools/{tenantId}/customPages/{pageId} collection.

    try {

      if(!lastPostDoc && this.sitePages$.getValue().length > 0) {

        return this.sitePages$.getValue()

      }

      const constraints: QueryConstraint[] = [
        orderBy("lastUpdatedAt", "desc"),
      ]

      if (lastPostDoc) {

        constraints.push(startAfter(lastPostDoc));

      }

      constraints.push(limit(pageNumber))

      const customPagesQuery = query(collection(this.firestore, `schools/${this.tenant.id}/customPages`), ...constraints)

      const customPagesData = await lastValueFrom(collectionData(customPagesQuery).pipe(first()))

      this.sitePages$.next([ ...this.sitePages$.getValue(), ...customPagesData as CustomPage[]])

      return customPagesData as CustomPage[]

    } catch (error) {
      const message = `Error loading all site pages`;
      console.error(message, error);
      this.messagesService.error(message);
    }

  }

  async createSitePage(page: CustomPage) {
    // create a new site page in the schools/{tenantId}/customPages/{pageId} collection.

    this.loadingService.loadingOn()

    try {

      const pageId = createFirestoreId(this.firestore)

      page.id = pageId

      const docRef = doc(this.firestore, `schools/${this.tenant.id}/customPages/${pageId}`)

      const newCustomPage = await setDoc(docRef, page)

      console.log('Creating blank page', page)

      const currentPages = [page, ...this.sitePages$.getValue()]

      this.sitePages$.next(currentPages)

      this.increaseTenantTotalCustomPages()

      this.loadingService.loadingOff()

      // this.messagesService.success('Blank page created')
      // this.snackBar.open("New custom page created.", 'Dismiss', {
      //   duration: 3000
      // })

      return page

  } catch (e) {

    this.messagesService.error(`Error creating custom page ${e.toString()}`)

    this.loadingService.loadingOff()

      throw Error(e)

  } finally {

      this.loadingService.loadingOff()

    }

  }

  async deleteCustomPage(pageId: string) {

    try {

      this.loadingService.loadingOn()

      const path = `schools/${this.tenant.id}/customPages/${pageId.toString()}`;

      const docRef = doc(this.firestore, path);

      await deleteDoc(docRef);

      const sitePages = this.sitePages$.getValue();
      const index = sitePages.findIndex((room) => room.id === pageId);

      sitePages.splice(index, 1);

      this.sitePages$.next(sitePages);

      this.decreaseTenantTotalCustomPages()

      this.loadingService.loadingOff()

      this.snackBar.open("Custom page successfully deleted.", "Dismiss", {
        duration: 3000
      })

    } catch (e) {
      this.loadingService.loadingOff()

      this.messagesService.error("Error occurred deleting Custom page")

    } finally {
      this.loadingService.loadingOff()
    }

  }

  invalidateCache() {
    this.sitePages$.next([])
  }

  async loadSitePageByUrl(url: string) {

    const constraints: QueryConstraint[] = [
      where("url","==", url ),
    ]

    const customPagesQuery = query(collection(this.firestore, `schools/${this.tenant.id}/customPages`), ...constraints)

    const customPagesData = await lastValueFrom(collectionData(customPagesQuery).pipe(first())) as CustomPage[]

    return customPagesData[0]

  }

  async updateCustomPage(page: CustomPage) {

    const pageId = page.id
    try {

      this.loadingService.loadingOn()

      const path = `schools/${this.tenant.id}/customPages/${pageId.toString()}`;

      const docRef = doc(this.firestore, path);

      page.lastUpdatedAt = Timestamp.now()

      await setDoc(docRef, page)

      const sitePages = this.sitePages$.getValue()
      const index = sitePages.findIndex(sitePage => sitePage.id === page.id)

      if(index !== -1) {

        sitePages[index] = page;

        this.sitePages$.next(sitePages)

      }

      this.editService.pageSaved()

      this.loadingService.loadingOff()

      this.snackBar.open("Custom page successfully updated.", "Dismiss", {
        duration: 3000
      })


    } catch (e) {

      console.error(e, page)

      this.loadingService.loadingOff()

      this.messagesService.error("Error occurred updating Custom page")

    } finally {

       this.loadingService.loadingOff()

    }

  }

  async searchSitePages(searchToken: string, filterByPageTypes?: string[]) {

    const constraints: QueryConstraint[] = [
      orderBy("lastUpdatedAt", "desc"),
    ]

    if(filterByPageTypes && filterByPageTypes.length) {

      constraints.push(
        where('type', 'in', filterByPageTypes)
      )

    }

    try {

      this.loadingService.loadingOn()

    const customPagesQuery = query(collection(this.firestore, `schools/${this.tenant.id}/customPages`), ...constraints)

    const customPagesData = await lastValueFrom(collectionData(customPagesQuery).pipe(first())) as CustomPage[]

    const searchResult = customPagesData.filter(customPageData => {

      if(!customPageData.title) {
        return false
      }

      return customPageData.title.toLowerCase().includes(searchToken.toLowerCase())
    })

      console.log(searchResult)

    this.sitePages$.next(searchResult)

      this.loadingService.loadingOff()

  } catch (error) {
      this.loadingService.loadingOff()
    const message = `Error searching for token in site pages`;
    console.error(message, error);
    this.messagesService.error(message);
  }

  }

  async setAsHomePage(pageId: string) {

    try {

      this.loadingService.loadingOn()

      const path = `schools/${this.tenant.id}/customPages`

      const batch = writeBatch(this.firestore)

      const customPages = await getDocs(collection(this.firestore, path))

      console.log(pageId)

      customPages.forEach((customPage) => {

        const docRef = doc(this.firestore, path + '/' + customPage.id);

        if (customPage.id === pageId) {

          batch.update(docRef, {...customPage.data(), isHomePage: true});

        } else {

          batch.set(docRef, {...customPage.data(), isHomePage: false} as CustomPage);

        }

      });

      await batch.commit();

      const sitePages = this.sitePages$.getValue().map(sitePage => {

        if(sitePage.id === pageId) {

          this.homePage$.next({...sitePage, isHomePage: true })

          return {...sitePage, isHomePage: true }

        } else {

          return {...sitePage, isHomePage: false }

        }

      })

      this.sitePages$.next(sitePages)

      this.loadingService.loadingOff()

    } catch (error) {

      this.loadingService.loadingOff()
      const message = `Error setting page as home page`;
      console.error(message, error);
      this.messagesService.error(message);

    }

  }

  async unSetAsHomePage(pageId: string) {

    try {

      this.loadingService.loadingOn()

      const path = `schools/${this.tenant.id}/customPages`

      const batch = writeBatch(this.firestore)

      const customPages = await getDocs(collection(this.firestore, path))

      console.log(pageId)

      customPages.forEach((customPage) => {

        const docRef = doc(this.firestore, path + '/' + customPage.id);

        if (customPage.id === pageId) {

          batch.update(docRef, {...customPage.data(), isHomePage: false});

        } else {

          batch.set(docRef, {...customPage.data(), isHomePage: false} as CustomPage);

        }

      });

      await batch.commit();

      const sitePages = this.sitePages$.getValue().map(sitePage => {

          return {...sitePage, isHomePage: false }

      })

      this.sitePages$.next(sitePages)

      this.homePage$.next(null)

      this.loadingService.loadingOff()

    } catch (error) {

      this.loadingService.loadingOff()
      const message = `Error unsetting page as home page`;
      console.error(message, error);
      this.messagesService.error(message);

    }

  }

  async getHomePage() {

    const constraints: QueryConstraint[] = [
      where("isHomePage","==", true ),
    ]

    const customPagesQuery = query(collection(this.firestore, `schools/${this.tenant.id}/customPages`), ...constraints)

    const customPagesData = await lastValueFrom(collectionData(customPagesQuery).pipe(first())) as CustomPage[]

    this.homePage$.next(customPagesData[0])

    return customPagesData[0]

  }

  getHomePage$(tenantId: string) {

    const constraints: QueryConstraint[] = [
      where("isHomePage","==", true ),
    ]

    const customPagesQuery = query(collection(this.firestore, `schools/${tenantId}/customPages`), ...constraints)

    const customPagesData = collectionData(customPagesQuery).pipe(first())

    return customPagesData

  }


  findCustomSubDomain(subDomain: string) {

    const constraints = [
      where('url', '==', subDomain)
    ]

    const customSubDomainQuery = query(collection(this.firestore, `schools/${this.tenant.id}/customPages`), ...constraints)

    const customSubDomainSnap = collectionData(customSubDomainQuery)

    return customSubDomainSnap.pipe(
      map(results => results.length == 1 ? results[0] : null),
      first()
    );
  }

  decreaseTenantTotalCustomPages() {

    if(this.isUnlimitedPlan()) {
      return
    }

    if(this.tenant.tenant.totalCustomPages > 0) {
      this.tenant.tenant.totalCustomPages--
    }

  }

  increaseTenantTotalCustomPages() {

    if(this.isUnlimitedPlan()) {
      return
    }

    const maxCustomPages = this.maxCustomPages()

    if(this.tenant.tenant.totalCustomPages < maxCustomPages) {
      this.tenant.tenant.totalCustomPages++
    }

  }

  isUnlimitedPlan() {
    const instructorPricingPlan = this.tenant.tenant.instructorPricingPlan

    return instructorPricingPlan /*instructorPlans[instructorPricingPlan]*/ === "unlimited"

  }

  maxCustomPages() {
    const instructorPricingPlan = this.tenant.tenant.instructorPricingPlan

    const instructorPlan = instructorPlans[instructorPricingPlan]

    return +instructorPlan.maxCustomPages

  }


}
